import React, { useState } from 'react';
import Calendar from 'react-awesome-calendar';
import Header from '../Header/Header'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import Footer from '../Footer/Footer'
import CustomBreadCumb from '../CustomBreadCumb/CustomBreadCumb'
import { Container } from 'react-bootstrap';
const Acadmenic = () => {
  
  const events = [{
    id: 1,
    color: '#fd3153',
    from: '2019-05-02T18:00:00+00:00',
    to: '2019-05-05T19:00:00+00:00',
    title: 'This is an event'
}, {
    id: 2,
    color: '#1ccb9e',
    from: '2019-05-01T13:00:00+00:00',
    to: '2019-05-05T14:00:00+00:00',
    title: 'This is another event'
}, {
    id: 3,
    color: '#3694DF',
    from: '2019-05-05T13:00:00+00:00',
    to: '2019-05-05T20:00:00+00:00',
    title: 'This is also another event'
}];
  return (
    <div>
        <Header />
        <NavbarMenu />
        <CustomBreadCumb breadname="Academic Calendar" />
        <div className='d-flex justify-content-center'>
        <h4 className='text-center mt-3 mb-3'>School <span className='underline'> Calendar</span></h4>

        </div>
<Container >
<Calendar
                events={events}
            />
</Container>
        <Footer />
    </div>
  )
}

export default Acadmenic