import React from 'react'
import Header from '../Header/Header'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import Footer from '../Footer/Footer'
import CustomBreadCumb from '../CustomBreadCumb/CustomBreadCumb'
import { Card, Col, Container, Row } from 'react-bootstrap'
const AboutUs = () => {
  return (
    <div>
       <Header />
        <NavbarMenu />
        <CustomBreadCumb breadname="About School" />
        <div className='d-flex justify-content-center'>
        <h1 className='text-center mt-3 mb-3'>About<span className='underline'> School</span></h1>
        </div>
        <Container>
          <Row>
            <Col lg={3}>
              <Card className='p-2 rounded-0 shadow'>
                <Card.Img src="images/3.jpg" />
              </Card>
            </Col>
            <Col lg={9} className="mt-2">
              <p>दानापुर मंडल में खगौल स्थित रेलवे स्कूल की स्थापना 14
था। प्रारम्भ में यह विद्यालय कलकत्ता विश्वविद्यालय से संबद्ध था।
नवम्बर, 1889 को हुई थी। उस समय यह विद्यालय E.I.R. के अधीन
 समिति पटना से संबद्ध हुआ। इस विद्यालय के सौ वर्ष पूरे होने पर
 कालांतर में यह पटना विश्वविद्यालय से तथा बिहार विद्यालय परीक्षा
रेलवे बोर्ड ने अपनी प्रसन्नता जाहिर करते हुए इसे इंटरमीडिएट स्तर
तक अपग्रेड कराने का आदेश पारित किया तथा बाद में इसे वरीय
 मंडल कार्मिक अधिकारी दानापुर ने इसे CBSE से संबद्धता दिलाने में
अपनी महती भूमिका निभाई। जिसके फलस्वरूप यह विद्यालय अप्रैल,
2016 से सीधे CBSE बोर्ड से संबद्ध हो गया। इस विद्यालय में 1 से
12वीं तक की शिक्षा प्रदान की जाती है। इंटर में कला और विज्ञान
संकाय में नामांकन लिया जा रहा है। शिक्षा का माध्यम अंग्रेजी है
लेकिन अगर छात्र चाहे तो हिन्दी में भी परीक्षा दे सकते हैं।
अब तक इस विद्यालय से पढ़कर बहुतेरे छात्रों ने आई०ए०एस०
आई०पी०एस०, आई०एफ०एस०, आई०आर०पी०एस०, जज,
डॉक्टर, इंजीनियर, रेलवे बोर्ड के मेम्बर प्रभृति कई ऊँचे ओहदे को
वकील,</p>
            </Col>
            <Col lg={9} className="mt-3">
            
            <p> सुशोभित किया है। रेलवे स्कूल की जमीन को स्थानीय जमींदार
रायबहादुर बाबू अनुग्रह नारायण सिंह जी ने दान में भेंट की थी। अभी
भी स्कूल के प्रति उनके परिवार का स्नेह एवं लगाव बना हुआ है
'Child is a father of man' तथा As we sow so we reap. बालक
मानव का जनक होता है तथा जैसा हम बोते हैं, वैसा ही काटते हैं।
इस उक्ति को चरितार्थ करने में इस विद्यालय के प्रत्येक शिक्षक
एवं सहकर्मी अपनी-अपनी भूमिका का सफल निर्वाह कर रहे हैं। हमें
खगौल की जनता, रेल प्रशासन एवं अभिभावकों से भी पूरा पूरा
सहयोग मिल रहा है। जिस प्रकार गागर में सागर, समुद्र में सीपी और
स्वाति में चातक की जो पिपासा होती है उसी प्रकार हमारे छात्र ज्ञान
पिपासु बनकर अपनी प्रतिभा को समुन्नत बनाये रखें एवं आदर्श
नागरिक बनकर राष्ट्र निर्माण में अपनी महती भूमिका अदा करें, हमारी
यही शुभकामना है।
शुभैषी
ज्ञानेश्वर</p>
            </Col>
            <Col lg={3}>
              <Card className='p-2 rounded-0 shadow'>
                <Card.Img src="images/1.jpg" />
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
    </div>
  )
}

export default AboutUs