import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

const Principal = () => {
  return (
    <div>
        <Container className='mt-5'>
            
            <Row>
                <Col lg={8} >
                <h2 className='fw-bold mt-2 mb-4'>Principal Message</h2>
                <p>
                Our School, East central railway sr. secondary school (ECRSSS) located at Danapur, Khagaul , District Patna-801105.
                We are feeling proud to be the pricipal of this well reputaed school.</p>
               <p> The School has been recognized by Central Board of Secondary Education since a long time with  three star.
                This has been rated on the basis of our best academinc records, Sports, & cultural Activities.
                Our school is counted among the top-rated Schools in Bihar with an excellent academic track record.
                We have great faculty teachers having best teaching experience in his/her subject.</p>
                <p>So students, please utilise all the resources of the school to improve your skills.
                There is an always best compliments from my side to the all students for their future.
                </p>
                </Col>
                <Col lg={4} >
                <Card className='p-2 rounded-0'>
                        <Card.Img src='images/HMIMAGE.jpeg'className='rounded-0'/>
                        <Card.Body>
                            <Card.Title className='text-center'>GYANESHWAR</Card.Title>
                            <Card.Text className='text-center'>M.A ; B.Ed</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Principal