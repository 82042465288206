import React,{useCallback, useState, useEffect} from 'react'
import { Card } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from "react-images";
import './MovingGallery.css';
const MovingGallery = () => {
  const [getdata, setgetdata] = useState([]);
  const fetchData = () => {
    fetch("https://ecrsssdnr.in/admin/galleryapi")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setgetdata(data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, {getdata, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };



      const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ['<i class="fas fa-angle-left " aria-hidden="true"></i>', '<i class="fas fa-angle-right" aria-hidden="true"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
    
            }
        },
    };
  return (
    <div className='container-fluid'>
<h2 className='text-center mb-5 mt-3 ' style={{fontWeight:"700", fontSize:"38px"}}>School Gallery</h2>
<OwlCarousel 
        className="owl-carousel owl-theme service-slider"
        {...options} 
                  >
{getdata.map(index => (
    <Card className='p-2 rounded-0 shadow mb-3' >
        <Card.Img src={index.src} className='img-fluid rounded-0 movingcard' onClick={openLightbox}  />
    </Card>   
     ))}  
   
 </OwlCarousel>
 <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={getdata.map((x) => ({
               
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default MovingGallery