import React, {useState, useEffect} from 'react'
import Header from '../Header/Header'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import Footer from '../Footer/Footer'
import CustomBreadCumb from '../CustomBreadCumb/CustomBreadCumb'
import axios from 'axios'
import { Card, Modal, Table } from 'react-bootstrap';
import "./style.css"
const Faculty = () => {
    const [GetData, SetPost] = useState([]);
    const [tid,setTid]=useState([])
    useEffect(() => {
  
      const fetchPosts = async () =>{
        const res = await axios.get("https://ecrsssdnr.in/admin/allteachersapi");
        SetPost(res.data)
      }
      fetchPosts();
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (tid) =>{ 
      setTid(tid)
      setShow(true);}
  return (
    <div>
     <Header />
        <NavbarMenu />
        <CustomBreadCumb breadname="Faculty" />
        <section className="team" id="team">
        <div className="container text-center">
            <h1 className='text-dark special'>Our Teachers
                </h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                <br></br>incididunt ut labore et dolore magna aliqua.</p> */}
            <div className="row">
            {GetData.map((item)=>{return(
                <div className="col-lg-3 col-md-6 mt-4">
                    <div className="box ">
                        <div className="image">
                            <img src={item.img} alt="team" className="img-fluid" />
                            <div className="social-icons">
                            <a href="javacriptvoid:0" type='button' onClick={()=>handleShow(item.id)}><i className="fas fa-eye"></i> View</a>
                               
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <h3>{item.name}</h3>
                        <h4>{item.quali}</h4>
                    </div>
                </div>
                )})}
            </div>
        </div>
    </section>
    <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {GetData.map((item)=>{
            if(item.id===tid){

            
            return(<>
          <div className='d-flex justify-content-center mt-2 '>
                    <img 
                    style={{height:"250px", width:"200px"}}
                    src={item.img} alt="teacher-image" />
          </div>
         <Table size='sm' className='mt-2' striped hover bordered>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{item.name}</td>
                </tr>
                <tr>
                  <th>Qualification</th>
                  <td>{item.quali}</td>
                </tr>
                <tr>
                  <th>Subject</th>
                  <td>{item.profession}</td>
                </tr>
                <tr>
                  <th>Degination</th>
                  <td>{item.degi}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{item.email}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{item.phone}</td>
                </tr>
              </tbody>
         </Table>
         <Card>
          <Card.Body>
            <Card.Text>{item.desc}</Card.Text>
          </Card.Body>
         </Card>
         </>)
            }
          })}
        </Modal.Body>
   
      </Modal>
        <Footer />
    </div>
  )
}

export default Faculty