import React,{useState, useEffect} from 'react'
import Header from '../Header/Header'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import Footer from '../Footer/Footer'
import CustomBreadCumb from '../CustomBreadCumb/CustomBreadCumb';
import { Button, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Notification = () => {
    const [getdata, setgetdata] = useState([]);
    console.log(getdata)
    const fetchData = () => {
      fetch("https://ecrsssdnr.in/admin/noticefetch")
        .then(response => {
          return response.json()
        })
        .then(data => {
          setgetdata(data)
        })
    }
  
    useEffect(() => {
      fetchData()
    }, [])
    var num=1;
  return (
    <div>
        <Header />
        <NavbarMenu />
        <CustomBreadCumb breadname="Notification" />
        <Container>
        <Table striped bordered hover size='sm'>
      <thead>
        <tr>
          <th>#</th>
          <th>Notification</th>
          <th className='text-center'>Download</th>
        </tr>
      </thead>
      <tbody>
      {getdata.map(notice => (<>
        <tr>
          <td>{num++}</td>
          <td>{notice.title}</td>
          <td className='text-center'><Button variant='secondary' size='sm' as={Link} to={`/notice/${notice.id}`}><i className='fas fa-eye '></i> View</Button></td>
        </tr>
        </>))}

  
      </tbody>
    </Table>
        </Container>
        <Footer />
        </div>
  )
}

export default Notification