import React from 'react'
import Header from '../Header/Header'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import Footer from '../Footer/Footer'
import CustomBreadCumb from '../CustomBreadCumb/CustomBreadCumb';

const Syllabus = () => {
  return (
    <div>
        <Header />
        <NavbarMenu />
        <CustomBreadCumb breadname="Syllabus" />
        <Footer />
    </div>
  )
}

export default Syllabus